<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <apexchart
          width="100%"
          height="400"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["barChartOptions", "barSeries"],

  data() {
    return {
      graphData: {
        graph: [],
      },
    };
  },
  computed: {
    series: function () {
      return [
        {
          name: "Cases",
          data: this.barSeries,
        },
      ];
    },
    chartOptions: function () {
      return {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.barChartOptions,
        },
        yaxis: {
          title: {
            text: "(District wise)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
    },
  },
};
</script>