<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader :storage="appLoading" />
      <v-layout wrap justify-center pt-4>
      <v-flex>
        <v-tabs
          fixed-tabs
          v-model="tabValue"
          color="#000"
          centered
          style="overflow-x:auto"
          :slider-color="appColor"
        >
          <v-tab v-for="item in titleArray" :key="item.value">
            <span class="itemHeading"> {{ item.title }} </span>
          </v-tab>
        </v-tabs>
      </v-flex>
    </v-layout>
    <v-tabs-items v-model="tabValue">
      <template v-for="(item, index) in titleArray">
        <v-tab-item :key="item.value" :value="index">
          <v-layout wrap v-if="item.value == 'pieChart'">
            <v-flex xs12 pt-4 text-center>
              <PieChart
                :pieSeries="pieSeries"
                :pieChartOptions="pieChartOptions"
              />
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="item.value == 'barGraph'">
            <v-flex xs12 pt-4>
              <BarGraph
                :barSeries="barSeries"
                :barChartOptions="barChartOptions"
              />
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="item.value == 'lineGraph'">
            <v-flex xs12 pt-4>
              <LineGraph
                :lineSeries="lineSeries"
                :lineChartOptions="lineChartOptions"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>
      </template>
    </v-tabs-items>
    <!-- <v-layout wrap justify-start pa-5>
      <v-flex xs12>
        <span class="itemHeading">Animal Section</span>

        <PieChart :pieSeries="pieSeries" :pieChartOptions="pieChartOptions" />
      </v-flex>
      <v-flex xs12 py-4>
        <span class="itemHeading">Report Section</span>

        <BarGraph :barSeries="barSeries" :barChartOptions="barChartOptions" />
      </v-flex>

      <v-flex xs12>
        <span class="itemHeading">Report Section</span>

        <LineGraph :analytics="analytics.linegraph" />
      </v-flex>
    </v-layout> -->
  </div>
</template>
<script>
import axios from "axios";
import PieChart from "./pieChart";
import BarGraph from "./barGraph";
import LineGraph from "./lineGraph";
export default {
  components: { PieChart, BarGraph, LineGraph },
  data() {
    return {
      closeDialogue: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      analytics: [],
      appLoading: false,
      ServerError: false,
      pieSeries: [],
      pieChartOptions: [],
      barSeries: [],
      barChartOptions: [],
       lineSeries: [],
      lineChartOptions: [],
        titleArray: [
        { title: "Animal Section", value: "pieChart" },
        { title: "District wise", value: "barGraph" },
        { title: "Action wise", value: "lineGraph" },
      ],
      tabValue:0
    };
  },

  mounted() {
    this.getAnalytics();
  },

  methods: {
    getAnalytics() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/analytics",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.analytics = response.data;
            for (var i = 0; i < this.analytics.piechart.length; i++) {
              this.pieChartOptions.push(this.analytics.piechart[i].name);
              this.pieSeries.push(this.analytics.piechart[i].count);
            }
            for (var j = 0; j < this.analytics.bargraph.length; j++) {
              this.barChartOptions.push(this.analytics.bargraph[j].district);
              this.barSeries.push(this.analytics.bargraph[j].year1Reports);
            }
              var series1=[] 
              var series2=[]
              var series3=[]
              var series4=[]
              for (var k = 0; k < this.analytics.linegraph.length; k++) {
              this.lineChartOptions.push(this.analytics.linegraph[k].month);
              series1.push(this.analytics.linegraph[k].year1HumanInjury)
              series2.push(this.analytics.linegraph[k].year1HumanDeath)
              series3.push(this.analytics.linegraph[k].year1AnimalInjury)
              series4.push(this.analytics.linegraph[k].year1AnimalDeath)
              
            }
            this.lineSeries.push(
                {
                 name:"Human Injury" ,
                 data:series1
                },
                 {
                 name:"Human Death" ,
                 data:series2
                },
                 {
                 name:"Animal Injury" ,
                 data:series3
                },
                 {
                 name:"Animal Death" ,
                 data:series1
                }
              )
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>