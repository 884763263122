<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <apexchart
          width="100%"
          height="400"
           type="line"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["lineChartOptions", "lineSeries"],

  data() {
    return {
      graphData: {
        graph: [],
      },
    };
  },
  computed: {
    series: function () {
      return [
        {
          name: this.lineSeries[0].name,
          data: this.lineSeries[0].data,
        },
         {
          name: this.lineSeries[1].name,
          data: this.lineSeries[1].data,
        },
         {
          name: this.lineSeries[2].name,
          data: this.lineSeries[2].data,
        },
         {
          name: this.lineSeries[3].name,
          data: this.lineSeries[3].data,
        },
      ];
    },
    chartOptions: function () {
      return {
         chart: {
          id: "Apiaries",
          toolbar: {
            show: false,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          sparkline: {
            enabled: false,
          },
        },
        xaxis: {
          categories: this.lineChartOptions,
          // categories: [
          //   "Jan",
          //   "Feb",
          //   "Mar",
          //   "Apr",
          //   "May",
          //   "Jun",
          //   "Jul",
          //   "Aug",
          //   "Sep",
          //   "Oct",
          //   "Nov",
          //   "Dec",
          // ],
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "gradient",
          gradient: {
            // shadeIntensity: 1,
            // opacityFrom: 0.7,
            // opacityTo: 0.9,
            // stops: [0, 90, 100],
            // colorStops: [
            //   {
            //     offset: 0,
            //     color: "#069EFC",
            //     opacity: 1,
            //   },
            //   {
            //     offset: 100,
            //     color: "#14F4C9",
            //     opacity: 1,
            //   },
            // ],
          },
        },
     
       
       
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
    },
  },
};
</script>