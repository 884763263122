<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 sm6 md6 lg4 align-self-center>
        <apexchart
          type="donut"
          height="400"
          width="100%"
          style="font-size: 6px; "
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["pieChartOptions", "pieSeries"],

  data() {
    return {};
  },
  computed: {
    series() {
      return this.pieSeries;
    },
    chartOptions: function () {
      return {
        chart: {
          type: "donut",
        },

        labels: this.pieChartOptions,
        dataLabels: {
          style: {
            fontSize: "10px",
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "center",
              },
            },
          },
        ],
      };
    },
  },
  mounted() {
    //  this.series=this.pieSeries
    // this.chartOptions.labels=this.pieChartOptions
    //  for(var i=0;i<this.analytics1.length;i++)
    //  {
    //     this.series.push(this.analytics1[i].count);
    //   this.chartOptions.labels.push(this.analytics1[i].name);
    //  }
    // if(this.analytics.length>0)
    // { this.series.push(this.analytics1[0].count);
    //   this.chartOptions.labels.push(this.analytics1[0].name);}
  },
};
</script>
<style>
.apexcharts-legend{
padding-top: 50px!important;
text-align:left!important;
}

</style>